import React from "react"

import Seo from "../seo"
import CareerForm from "./form"

const CareerComponent = () => {
  return (
    <div id="CareerComponent" className="career-component py-5">
      <Seo
        title="Career"
        description="Thank you for considering a career with MAXapps."
        titleog="Please take a minute to fill out the following form."
        descriptionog="After you have completed your application an email will be sent to you with information about how to check the status of your application."
        image="https://maxapps-maximo.com/og/contact-us.jpg"
        url="https://maxapps-maximo.com/career/"
      />
      <h1>MAXapps Career</h1>
      <hr />
      <CareerForm />
    </div>
  )
}

export default CareerComponent
