import React, { useState } from "react"
import * as Yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"

import services from "../../../services"
import { sendMail } from "../../../services/mail"
import LottieAnimation from "../../../animations/lottie"

import "./index.scss"
const PersonalInfoForm = () => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .max(100, "*First Name must be less than 50 characters")
      .required("*This field is required"),
    lastName: Yup.string()
      .max(100, "*Last Name must be less than 50 characters")
      .required("*This field is required"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*This field is required"),
    phone: Yup.number()
      .typeError("That doesn't look like a phone number")
      .positive("A phone number can't start with a minus")
      .integer("A phone number can't include a decimal point")
      .required("A phone number is required"),
    city: Yup.string()
      .max(100, "*City name must be less than 100 characters")
      .required("*This field is required"),
    country: Yup.string()
      .max(100, "*Country name must be less than 100 characters")
      .required("*This field is required"),
    cv: Yup.string().required("*This field is required"),

    submit: Yup.string(),
  })
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const storage = services.firebaseServices.firebaseStorage

  return (
    <div id="personalInfoForm" className="personal-info-form">
      <div className="container col-12 col-md-12   ">
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            city: "",
            country: "",
            cv: "",
            submit: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setStatus, setErrors }
          ) => {
            setSubmitting(true)

            const uniqueFilename = generateUniqueFilename(values)

            console.log("====================================")
            console.log("form data: ", values)
            console.log("filename: ", uniqueFilename)
            console.log("====================================")

            let cvURL = "not available"
            try {
              const imageRef = ref(storage, `CVs/${uniqueFilename}`)
              const cvSnapshot = await uploadBytes(imageRef, values.cv)
              cvURL = await getDownloadURL(cvSnapshot.ref)
            } catch (error) {
              console.error("Error while uploading the file: ", error)
            }

            values.cv = cvURL
            delete values.submit

            const res = await sendMail(values, window.location.href)

            if (res.success) {
              resetForm({})
              setStatus({ success: true })
              setShowSuccessMsg(true)
            } else {
              setStatus({ success: false })
              setErrors({ submit: res.message })
            }

            setTimeout(() => {
              setShowSuccessMsg(false)
            }, 3000)

            setSubmitting(false)
          }}
        >
          {({ isSubmitting, touched, errors, setFieldValue }) => (
            <Form>
              <div className="form-input">
                <label htmlFor="firstName" className="form-label">
                  First Name*
                </label>
                <Field
                  type="text"
                  name="firstName"
                  className={`form-control ${
                    touched.firstName && errors.firstName ? "error" : null
                  }`}
                />
                <ErrorMessage
                  name="firstName"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-input">
                <label htmlFor="lastName" className="form-label">
                  Last Name*
                </label>
                <Field
                  type="text"
                  name="lastName"
                  className={`form-control ${
                    touched.lastName && errors.lastName ? "error" : null
                  }`}
                />
                <ErrorMessage
                  name="lastName"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-input">
                <label htmlFor="email" className="form-label">
                  Email*
                </label>
                <Field
                  type="email"
                  name="email"
                  className={`form-control ${
                    touched.email && errors.email ? "error" : null
                  }`}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-input">
                <label htmlFor="phone" className="form-label">
                  Phone*
                </label>
                <Field
                  type="phone"
                  name="phone"
                  className={`form-control ${
                    touched.phone && errors.phone ? "error" : null
                  }`}
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-input">
                <label htmlFor="city" className="form-label">
                  City*
                </label>
                <Field
                  type="city"
                  name="city"
                  className={`form-control ${
                    touched.city && errors.city ? "error" : null
                  }`}
                />
                <ErrorMessage
                  name="city"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-input">
                <label htmlFor="country" className="form-label">
                  Country*
                </label>
                <Field
                  type="country"
                  name="country"
                  className={`form-control ${
                    touched.country && errors.country ? "error" : null
                  }`}
                />
                <ErrorMessage
                  name="country"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="form-input">
                <label htmlFor="cv" className="form-label">
                  Resume (.pdf)*
                </label>
                <input
                  type="file"
                  name="cv"
                  className={`form-control ${
                    touched.cv && errors.cv ? "error" : null
                  }`}
                  accept="application/pdf"
                  onChange={event => setFieldValue("cv", event.target.files[0])}
                />
                <ErrorMessage
                  name="cv"
                  component="div"
                  className="error-message"
                />
              </div>
              <button
                type="submit"
                name="submit"
                disabled={isSubmitting}
                className="form-button submit-button "
              >
                Submit
                {!isSubmitting && <i className="bi bi-arrow-right-short "></i>}
                {isSubmitting && (
                  <div className="login-loader">
                    <LottieAnimation height={25} width={25} />
                  </div>
                )}
              </button>
              <ErrorMessage
                name="submit"
                component="div"
                className="error-message"
              />
              {showSuccessMsg && (
                <div className="success-msg">
                  <i className="bi bi-envelope-fill"></i>
                  <h6>
                    we have received your request, we will answer you as soon as
                    possible.
                  </h6>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const generateUniqueFilename = data => {
  const ext = data?.cv?.name?.split(".").pop()
  return `${data?.firstName?.slice(0, 3)}.${data?.lastName?.slice(0, 3)}.${
    data?.phone ? data.phone : ""
  }.${data?.city?.slice(0, 3)}.${data?.country?.slice(
    0,
    3
  )}.${Date.now()}.${ext}`
}

export default PersonalInfoForm
