import React from "react"

import PersonalInfoForm from "../../forms/personal-info-form"

import "./index.scss"
const CareerForm = ({
  // title1 = "Any inquiery ?",
  title2 = "Personal Informations",
}) => {
  return (
    <div id="CareerForm" className="career-form d-flex justify-content-center ">
      <div className="w500">
        {/* <h2 className="title-p1 text-left container ">{title1}</h2> */}
        <p className="title-p2 text-left container">{title2}</p>
        <PersonalInfoForm />
      </div>
    </div>
  )
}

export default CareerForm
