import React from "react"

import CareerComponent from "../components/career"
import Layout from "../components/layout/t1/layout"

const Career = () => {
  return (
    <Layout>
      <CareerComponent />
    </Layout>
  )
}

export default Career
